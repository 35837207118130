@font-face {
  font-family: 'CGE Mono';
    src: url('../fonts/firacode/firacode-regular-webfont.woff2') format('woff2'), url('../fonts/firacode/firacode-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CGE Mono';
    src: url('../fonts/firacode/firacode-medium-webfont.woff2') format('woff2'), url('../fonts/firacode/firacode-medium-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CGE Mono';
    src: url('../fonts/firacode/firacode-bold-webfont.woff2') format('woff2'), url('../fonts/firacode/firacode-bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
