.data-item
  &.data-item-inspection
    .data-item-content
      padding: 1rem 0 1.2rem 0
    .table
      th,
      td
        &:first-child
          padding-left: $card-spacer-x
          +media-breakpoint-up(lg)
            padding-left: $grid-gutter-width
        &:last-child
          padding-right: $card-spacer-x
          +media-breakpoint-up(lg)
            padding-right: $grid-gutter-width
      tr.not_inspected
        text-decoration: line-through
        opacity: 0.5
      td.points_actual
        white-space: nowrap
      tfoot
        .total
          float: right
      .result
        border-top-width: 1px
      .image_attachment_link
        align-self: flex-start
        cursor: zoom-in
        margin-top: 5px
        margin-right: 10px
        padding: 0.125rem 0.25rem
    .image
      @extend .row
      margin-top: $card-spacer-y


// new/create
// ---------------------------------------------------------------------

#new_inspection
  #inspected_services
    @extend .box_shadow_item
    border-bottom: 1px solid $gray-lighter
    border-bottom-left-radius: $border-radius-lg
    border-bottom-right-radius: $border-radius-lg
    .service
      background-color: white
      border-radius: 0
      border: 1px solid $gray-lighter
      border-bottom-width: 0
      overflow: hidden
      &.defective
        .header
          background-color: #f9dee0
      &.not_inspected
        .header
          text-decoration: line-through
          background-color: $gray-lighter
          a
            color: $gray!important
        .body-inner
          opacity: 0.5
      &:first-child
        border-top-left-radius: $border-radius-lg
        border-top-right-radius: $border-radius-lg
      &:last-child
        border-bottom-left-radius: $border-radius-lg
        border-bottom-right-radius: $border-radius-lg
        border-bottom-width: 1px
      .header
        margin: 0
        padding-left: $card-spacer-x
        padding-right: $card-spacer-x
        background-color: #ecf3ec
        a
          display: block
          padding-top: 17px
          padding-bottom: 17px
          position: relative
          padding-right: 20px
          &:after
            content: '' // Fontawesome caret-up
            position: absolute
            top: 50%
            right: 0
            font-family: FontAwesome
            font-size: 18px
            height: 16px
            margin-top: -10px
            opacity: 0.5
          &.collapsed
            &:after
              content: '' // Fontawesome caret-down
      .body
        .body-inner
          border-top: 1px solid $gray-lighter
          padding: 15px $card-spacer-x 10px $card-spacer-x
    .points_target_actual_slider_container
      margin-bottom: 35px
      padding: 0 20px
      +media-breakpoint-up(md)
        padding: 0 40px
    .form-group
      margin-bottom: 0
      &.points_target,
      &.points_actual
        float: left
        width: 50%
        margin-bottom: 0
        background: transparent
        label
          margin: 0
          &:after
            content: ": "
        input
          display: inline-block!important
          color: $body-color!important
          width: auto!important
          padding: 0 0 0 5px
          background: transparent
          border: none
          box-shadow: none
      &.points_actual
        text-align: right
        input
          margin-right: -15px
      &.percent
        clear: both
        height: 45px
        text-align: center
        &.percent-traffic-light
          .slider
            padding-right: 8px
            padding-left: 8px
          .slider-tick-label
            +hide-text
            width: auto!important
            margin-top: 5px
            &:after
              content: ' '
              display: inline-block
              height: 16px
              width: 16px
              border-radius: 50%
              background-color: $danger
            &:nth-child(1)
              &:after
                margin-left: -8px
            &:nth-child(2)
              &:after
                content: ''
                background-color: $warning
            &:last-child
              &:after
                content: ''
                margin-right: -8px
                background-color: $success
      &.toggle-disabled
        text-align: right
        margin-bottom: 5px
        .btn
          padding-left: 0

.inspections-edit, .inspections-update
  input[type="text"],
  button.crud_action.crud_action_update
    @extend .form-control-sm
  .clockpicker
    display: inline-block !important
    width: 55px !important
    margin-right: 4px
  .correction_date_date
    display: inline-block !important
    width: 85px !important
