// Override Select2 - style(s)
// ---------------------------------------------------------------------

// .select2-container
//   .select2-selection__placeholder,
//   .select2-results__options,
//   .select2-selection--single
//     font-size: 0.9375rem!important

// outline and border
.select2-container--bootstrap .select2-selection,
.select2-dropdown.select2-dropdown--below,
.select2-dropdown.select2-dropdown--above
  outline-color: $brand-primary!important
  border: 1px solid $input-border-color!important
.select2-dropdown
  -webkit-overflow-scrolling: touch // smooth scrolling
.select2-container--bootstrap.select2-container--open .select2-selection,
.select2-container--bootstrap.select2-container--focus .select2-selection
  box-shadow: $input-focus-box-shadow

.select2-container--bootstrap
  .select2-results__option
    padding-top: 10px
    padding-bottom: 11px
    &.select2-results__option--highlighted
      color: $body-color!important
      background-color: $dropdown-link-hover-bg!important
.select2-container--bootstrap .select2-selection__placeholder
  font-size: $font-size-base

.select2-container--bootstrap .select2-selection
  font-size: $font-size-base
  border-radius: $border-radius

.select2-selection.select2-selection--single
  padding: $input-padding-y $input-padding-x!important
  height: auto!important

select.is-invalid
  & + .select2-container--bootstrap .select2-selection,
  & + .select2-container--bootstrap.select2-container--disabled .select2-selection
    border: 1px solid $danger!important

.select2-container--bootstrap.select2-container--disabled .select2-selection
  background: $input-disabled-bg
  border: 1px solid $input-border-color

.select2-container--bootstrap .select2-search .select2-search__field
  font-size: $font-size-base
  padding: 7px
  padding-left: 24px
  background: url('../../assets/images/icon_search.svg') no-repeat 8px center
  background-size: 12px

// Optgroup
strong.select2-results__group
  color: $body-color!important
  font-weight: bold
  background: lighten($gray-lighter, 12)
