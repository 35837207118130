.table-responsive
  @extend .box_shadow_default
  background: white
  border-radius: $border-radius
  -webkit-overflow-scrolling: touch // smooth scrolling
  .table
    margin-bottom: 0
    &.table-responsive-card
      th,
      td
        &:first-child
          padding-left: $card-spacer-x
        &:last-child
          padding-right: $card-spacer-x
    &.table-responsive-data-item
      th,
      td
        &:first-child
          padding-left: $grid-gutter-width
        &:last-child
          padding-right: $grid-gutter-width

.table-responsive.table-responsive-full-width
  +media-breakpoint-down(xs)
    width: auto
    margin-right: - $content-padding
    margin-left: - $content-padding
    .table
      th:first-child,
      td:first-child
        padding-left: $content-padding

.table
  // background: white
  &.table-sm,
  &.table-sm-responsive,
  &.data-table
    td,
    th
      .btn-group
        margin-top: -4px!important
        margin-left: 0
  td,
  th
    &.truncate
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      // width: 100%
      max-width: 0
    .btn-group
      margin-top: -7px
      margin-bottom: -4px
  tfoot
    font-weight: bold
  .small
    font-size: 0.82em
  .card &,
  .tab-content &
    th
      border-top: none
  tr.linkable-row
    cursor: pointer
  tr.active td
    // background-color: rgba($brand-primary, 0.11)
    background-color: mix(lighten($success, 50), white, 75%)
  tr.inactive td
    color: $gray-light
  th
    white-space: nowrap
    &.crud_actions
      width: 70px
      &.crud_actions_rud
        width: 115px
    a.sortable
      color: $body-color
      &:after
        @extend .fa
        content: "\f0dc"
        padding-left: 3px
      &.current
        &.asc:after
          content: "\f160"
        &.desc:after
          content: "\f161"
.table-sm-responsive
  @extend .table-sm
  +media-breakpoint-up(xl)
    th,
    td
      padding: $table-cell-padding

.data-table
  @extend .table
  @extend .table-sm-responsive
  // @extend .table-hover
