.rs_dialog_holder
  position: fixed
  overflow-x: hidden
  overflow-y: auto
  left: 0px
  right: 0px
  top: 0px
  bottom: 0px
  width: 100%
  height: 100%
  padding-left: 50%
  background-color: rgba(255, 255, 255, 0.2)
  backdrop-filter: blur(3px)
  z-index: 1050
  .rs_dialog
    display: inline-block
    min-width: 350px
    margin-left: -175px
    overflow: hidden
    position: relative
    background-color: $body-bg
    border: 1px solid $gray-lightest
    border-radius: $border-radius-lg
    box-shadow: rgba(black, 0.1) 0px 17px 24px 0px, rgba(black, 0.1) 0px 20px 77px 0px
    .rs_dialog_content
      padding: 20px
      h1
        font-size: 16px
        padding: 0px
        margin: -20px -20px 30px -20px
        padding: 15px 20px
        background-color: white
        border-bottom: 1px solid $gray-lighter
        a
          text-decoration: none
          float: right
          display: inline-block
          margin-top: -12px
          background-position: center
          background-repeat: no-repeat
          &:before
            color: $body-color
            content: "×"
            font-size: 32px
            font-weight: bold
          &:hover:before
            color: $gray-light
      select
        @extend .custom-select

      input.rs_interval
        @extend .form-control

      .freq_option_section
        display: none
        label
          font-weight: bold
        .rs_interval
          max-width: 40px
          text-align: center
          display: inline-block

        .day_holder
          position: relative
          margin-top: 5px
          height: 35px
          a
            color: $body-color
            font-weight: bold
            display: block
            padding: 8px 13px
            font-size: 14px
            float: left
            text-decoration: none
            background-color: white
            border-style: solid
            border-color: $gray-light
            border-width: 1px 1px 1px 0px
            &:first-of-type
              border-radius: $border-radius-sm 0 0 $border-radius-sm
              border-left-width: 1px
            &:last-of-type
              border-radius: 0 $border-radius-sm $border-radius-sm 0
            &.selected
              color: white
              background-color: $brand-secondary
            &:hover
              cursor: pointer
              background-color: lighten($brand-secondary, 40)

        .rs_calendar_day,
        .rs_calendar_week
          width: 300px
          a
            font-size: 13px
            display: inline-block
            text-align: center
            width: 38px
            padding: 6px 4px
            border-style: solid
            border-color: $gray-lighter
            border-width: 1px 1px 1px 1px
            margin: -1px 0 0 -1px
            background-color: #fff
            font-weight: bold
            &.selected
              color: white
              background-color: $brand-secondary
            &:hover
              cursor: pointer
              background-color: lighten($brand-secondary, 40)
          a.end_of_month
            width: 81px
        .rs_calendar_week
          span
            display: inline-block
            width: 35px

        .monthly_rule_type
          span
            @extend .form-check
            @extend .form-check-inline
            label
              @extend .form-check-label
            input[type="radio"]
              @extend .form-check-input
              margin-left: 5px

      .rs_summary
        padding: 0px
        margin-top: 15px
        border-top: 1px solid #ccc
        span
          font-weight: bold
          border-top: 1px solid #fff
          display: block
          padding: 10px 0 5px 0
        &.fetching
          color: #999
          span
            // background-image: image-url("recurring_select/throbber_13x13.gif");
            background-position: center
            background-repeat: no-repeat
            display: inline-block
            height: 13px
            width: 13px
            margin-top: -4px
            padding-right: 5px
        label
          font-weight: normal

      .controls
        padding: 10px 0px 5px 0px
        min-width: 170px
        text-align: center
        input[type="button"]
          @extend .btn
          @extend .btn-outline-dark
          // &.rs_save
          //   @extend .btn-outline-primary
          // &.rs_cancel
          //   @extend .btn-outline-warning
          &.disabled
            @extend .btn-secondary

    &.animated
      .controls
        position: absolute
        bottom: 10px
        left: 10px
      .rs_summary,
      .freq_option_section
        display: none
