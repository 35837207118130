// index
// ---------------------------------------------------------------------

body.tickets-index
  .priority_indicator,
  .state_indicator
    margin-top: -0.2rem


// new/create
// ---------------------------------------------------------------------

#new_ticket
  .field_with_errors
    + #allocation_units
      border-color: $danger!important


// show
// ---------------------------------------------------------------------

.workflow-step
  .workflow-step-heading
    height: 0.8rem
    margin-top: 0.5rem
    margin-bottom: 0.8rem
    border-bottom: 1px solid $gray-lighter
  .workflow-step-heading .number
    display: inline-block
    font-size: 0.8rem
    text-align: center
    line-height: 1.3rem
    border: 1px solid $gray-lighter
    height: 1.4rem
    width: 1.4rem
    background-color: white
    border-radius: 1rem
  .workflow-step-content
    padding-left: 2rem
    p:last-of-type
      margin-bottom: 0


// Order state
// ---------------------------------------------------------------------

.ticket_order_progress
  display: inline-flex
  margin: 0.2em 0
  border: 1px solid $brand-secondary
  border-radius: 3px
  .step
    text-indent: -999em
    height: 1em
    width: 1em
    border-left: 1px solid $brand-secondary
    &:first-child
      border-left: none
    &.step_before
      background: rgba($brand-secondary, 0.25)
    &.step_current
      background: rgba($brand-secondary, 0.5)
  &.ticket_order_progress_approved
    .step
      background: rgba($brand-secondary, 0.5)


// Ticket Workflow
// ---------------------------------------------------------------------

form.edit_ticket_workflow,
form#new_ticket_workflow
  #ticket_workflow_memberships
    li.list-group-item
      display: flex
      justify-content: space-between
      &.marked_for_destruction
        opacity: 0.5
  .custom-switch
    .custom-control-label
      width: 0
      text-indent: -999em
