// new/create
// ---------------------------------------------------------------------

#map
  @extend .mt-2
  width: 100%
  padding-bottom:  62.5% // 10/16 = aspect ratio of image that is 16:10
  position: relative
  background: $gray-lightest
  border-radius: $border-radius
  img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: 1px solid rgba(0, 0, 0, 0.125)
    border-radius: calc(#{$border-radius} - 1px)

#time_tracking_location_progress
  color: white
  font-weight: bold
  text-align: center
  padding: 2rem
  background-color: rgba($brand-secondary, 0.5)
  border-radius: $border-radius-sm
  .async_loader
    @include loader(3px, 5px auto, white)

#time_tracking_types
  // integration tests fail otherwise
  @extend .multiple-selections-box !optional
  max-height: 222px
  margin-bottom: 30px

.field_with_errors
  + #time_tracking_types
    border-color: $danger!important


// Ongoing TimeTracking overlay
// ---------------------------------------------------------------------

#time_tracking_open
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 10
  color: $body-color
  font-weight: bold
  line-height: 3rem
  letter-spacing: -0.06em
  text-decoration: none
  height: 3rem
  padding: 0 $content-padding
  background: #fed71c
  @include media-breakpoint-up(sm)
    padding-right: $content-padding * 2
    padding-left: $content-padding * 2
  @include media-breakpoint-up(lg)
    left: $page_sidenav_width
  a
    color: $body-color
    text-decoration: none
  .end_time_tracking
    float: right
    .btn
      margin-bottom: 0.2rem
