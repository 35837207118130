.simple-calendar
  .calendar-heading
    display: flex
    justify-content: space-between
    padding: 0 0 1em 0
    .calendar-title
      font-size: 1.25em
      font-weight: bold
      letter-spacing: -0.05em
    a
      @extend .btn
      @extend .btn-sm
      @extend .btn-outline-dark
      font-size: 1.5em
      line-height: 18px
      width: 32px
      height: 32px
      &:nth-child(2)
        font-size: 1em
        line-height: 1.35em
        width: auto
  .table
    @extend .box_shadow_default
    background: white
    border: none
    border-radius: $border-radius
    border-collapse: collapse
    table-layout: fixed
    tr
      &:last-child td:first-child
        border-bottom-left-radius: $border-radius
      &:last-child td:last-child
        border-bottom-right-radius: $border-radius
    th,
    td
      text-align: right
    td
      border-right: 1px solid $gray-lightest
      border-left: 1px solid $gray-lightest
      &:first-child
        border-left: none
      &:last-child
        border-right: none
  .day
    $day_number_size: 2rem
    height: 6rem // with td, this works like min-height
    background: white
    &.next-month,
    &.prev-month
      strong
        color: $gray-lighter
    &.today
      .day_number
        @extend .bg-primary
        color: white
        text-align: center
        border-radius: math.div($day_number_size, 2)
    .day_number
      display: inline-block
      font-size: 1.19em
      font-weight: normal
      letter-spacing: -0.08em
      line-height: $day_number_size
      height: $day_number_size
      width: $day_number_size
      margin-bottom: 0.5rem
    a.event
      @extend .btn
      @extend .btn-sm
      @extend .btn-info
      @extend .btn-block
      display: flex
      text-align: left
      margin-top: 5px
      padding-top: 1px
      padding-bottom: 1px
