// Off canvas navigation
// --------------------------------------------------

@mixin offcanvas {
  display: none;
  &.in {
    display: block;
  }
}

.offcanvas {
  @include offcanvas;
}

@include media-breakpoint-down(sm) {
  .offcanvas-xs {
    @include offcanvas;
  }
}
@include media-breakpoint-down(md) {
  .offcanvas-sm {
    @include offcanvas;
  }
}
@include media-breakpoint-down(md) {
  .offcanvas-md {
    @include offcanvas;
  }
}
.offcanvas-lg {
  @include offcanvas;
}

.canvas-sliding {
  transition: top 0.15s, left 0.15s, bottom 0.15s, right 0.15s;
}

.offcanvas-clone {
  height: 0px !important;
  width: 0px !important;
  overflow: hidden !important;
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  position: absolute !important;
  top: auto !important;
  left: auto !important;
  bottom: 0px !important;
  right: 0px !important;
  opacity: 0 !important;
}

.modal-backdrop.allow-navbar {
  z-index: 1029;
}

.limit-backdrop {
  overflow: hidden!important;

  .modal-backdrop {
    position: absolute;
  }
}
