// Text selection
$prefixes: ("-webkit-","-moz-", "-o-", "")
@mixin selection($color, $background)
  @each $prefix in $prefixes
    ::#{$prefix}selection
      color: $color
      background: $background

// Arrow/Triangle
// @param width    :  px, em
// @param height   :  px, em
// @param direction:  up, down, left, right
// @param color    :  hex, rgb
@mixin arrow($width: 20px, $height: 20px, $direction: up, $color: red)
  width: 0
  height: 0
  // Right
  @if $direction == right
    border-top: $height*0.5 solid transparent
    border-bottom: $height*0.5 solid transparent
    border-left: $width solid $color
  // Left
  @if $direction == left
    border-top: $height*0.5 solid transparent
    border-bottom: $height*0.5 solid transparent
    border-right: $width solid $color
  // Up
  @if $direction == up
    border-left: $width*0.5 solid transparent
    border-right: $width*0.5 solid transparent
    border-bottom: $height solid $color
  // Down
  @if $direction == down
    border-left: $width*0.5 solid transparent
    border-right: $width*0.5 solid transparent
    border-top: $height solid $color

@mixin dog-ear($size: 25px, $foreground-color: gray, $background-color: white)
  position: relative
  &:before
    position: absolute
    z-index: 10
    top: 0
    right: 0
    content: ""
    width: 0
    height: 0
    border-top: $size solid $background-color
    border-left: $size solid $foreground-color
    box-shadow: -5px 5px 5px rgba(black, 0.05), -1px 1px 1px rgba(black, 0.05)

// Stretch 100% height and width, parent must have position set
.stretch-all
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  height: 100%
  width: 100%



// Loading animation
@mixin loader($size: 5px, $margin: 20px, $color: white)
  font-size: $size
  position: relative
  text-indent: -9999em
  margin: $margin
  border-top: 1em solid rgba($color, 0.2)
  border-right: 1em solid rgba($color, 0.2)
  border-bottom: 1em solid rgba($color, 0.2)
  border-left: 1em solid $color
  transform: translateZ(0)
  animation: load8 0.5s infinite linear
  border-radius: 50%
  width: 10em
  height: 10em
  &:after
    border-radius: 50%
    width: 10em
    height: 10em

  @keyframes load8
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(360deg)

@mixin hide-text
  font: 0/0 a
  color: transparent
  text-shadow: none
  background-color: transparent
  border: 0

.hide-text
  +hide-text

@keyframes moving-gradient
  0%
    background-position: left bottom
  100%
    background-position: 100px bottom

// Only retina displays
@mixin retina
  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx)
      @content
