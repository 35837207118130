.separator
  color: $gray-light

.page-header
  margin: 20px 0 20px 0
  @include media-breakpoint-up(md)
    margin: 40px 0 30px 0
  h1,
  h2
    margin-top: 1em
h1, h2, h3, h4, h5, h6
  font-weight: 800
  letter-spacing: -0.03em
  /* Careful, this breaks the word wherever it is without a hyphen */
  overflow-wrap: break-word
  word-wrap: break-word
  /* Adds a hyphen where the word breaks */
  hyphens: auto
  code
    font-weight: 800
h1
  font-size: 1.75rem
  @include media-breakpoint-up(md)
    font-size: 2rem
  margin-top: 5px
h2
  font-size: 1.3175rem
  @include media-breakpoint-up(md)
    font-size: 1.5rem
  margin: 1.7em 0 0.7em 0
h3
  font-size: 1.1875rem
  @include media-breakpoint-up(md)
    font-size: 1.25rem
h4
  font-size: 1.0625rem
h5
  font-size: 1rem
h6
  font-size: 0.9375rem
strong,
b
  font-weight: bold
.extrabold
  font-weight: 800
h6
  font-size: 0.875rem

small,
.small
  font-size: 0.95em

.hint
  @extend .text-muted
  @extend .small
  p
    margin-top: 0.5em
    margin-bottom: 0

.tiny
  font-size: 0.75em

pre,
code,
kbd,
samp,
tt
  font-size: 1em
pre
  font-weight: normal
  background: rgba(white, 0.8)
  border: none
  white-space: pre-wrap
  word-wrap: break-word
  &.highlight
    font-size: 14px
code
  color: $body-color
  background: none

.title
  font-size: 0.75em
  text-transform: uppercase

.huge
  font-size: 24px

.text-dark
  color: $body-color!important

.ellipsis
  display: inline-block
  text-overflow: ellipsis
  overflow: hidden
  max-width: 100%

span.cgx
  font-family: $font-family-sans-serif
  font-weight: 800
  letter-spacing: -0.05em

span.emoji-plain
  color: transparent
  font-family: monospace
  text-shadow: 0 0 $body-color

.keyboard-keys
  padding: 1px 4px
  border: 1px solid $gray-light
  border-radius: $border-radius-sm

p,
ul,
ol
  &.lead
    font-size: 1.25em
    font-weight: normal

dl
  dt,
  dd
    margin-bottom: 0.8rem
  p
    margin: 0.4rem 0
  &.dl-horizontal
    margin-bottom: 0
    dt,
    dd
      margin-bottom: 0
      +media-breakpoint-up(md)
        border-top: 1px solid $gray-lightest
        &:first-of-type
          border-top: none
    dt
      padding-top: 0.8rem
      &:first-of-type
        padding-top: 0
      +media-breakpoint-up(md)
        float: left
        width: 160px
        clear: left
        padding-top: 0.75rem
      +media-breakpoint-up(lg)
        width: 220px
      // text-align: right
      // overflow: hidden
      // text-overflow: ellipsis
      // white-space: nowrap
    dd
      padding-top: 0.4rem
      padding-bottom: 0.8rem
      border-bottom: 1px solid $gray-lightest
      &:first-of-type
        padding-top: 0
      &:last-of-type
        padding-bottom: 0
        border-bottom: none
      +media-breakpoint-up(md)
        margin-left: 160px
        padding-left: 20px
        padding-top: 0.75rem
        padding-bottom: 0.75rem
        border-bottom: none
      +media-breakpoint-up(lg)
        margin-left: 220px

.list-tree
  $border-color: $gray-lighter
  list-style-type: none
  padding-left: 0
  li
    position: relative
    padding-bottom: 0.5rem
    padding-left: 0.9rem
    border-left: 1px solid $border-color
    // dash from left border to text
    &:before
      content: " "
      position: absolute
      top: 0.5rem
      left: 0
      height: 1px
      width: 8px
      background: $border-color
    // hide left border and add custom smaller one
    &:first-child
      border-left: none
      &:after
        content: " "
        position: absolute
        top: 0.5rem
        left: 0
        height: 100%
        width: 1px
        background: $border-color
    // hide left border and add custom smaller one
    &:last-child
      border-left: none
      &:after
        content: " "
        position: absolute
        top: 0
        left: 0
        height: 0.5rem
        width: 1px
        background: $border-color
    &:only-child
      &:after
        content: none

abbr[title]
  position: relative

  /* ensure consistent styling across browsers
  text-decoration: underline dotted

  &:hover::after, &:focus::after
    content: attr(title)

    /* position tooltip like the native one
    z-index: 10
    position: absolute
    left: 0
    bottom: -30px
    width: auto
    white-space: nowrap

    /* style tooltip
    background-color: #1e1e1e
    color: #fff
    border-radius: 3px
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4)
    font-size: 14px
    padding: 3px 5px
