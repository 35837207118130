.data-item
  &.data-item-monitoring-sheet
    .data-item-content
      padding: 1rem 0 1.2rem 0

#monitoring_sheet-evaluate
  table
    th.position
      width: 30px
    th.value
      min-width: 150px
      width: 250px
  .form-group
    margin: 0
    padding: 0
  .custom-control.custom-switch
    margin-top: 0.75rem
    margin-bottom: 0.75rem

form.edit_monitoring_sheet .input-group-prepend .input-group-text
  font-size: 0.69rem
  text-align: center
  justify-content: center
  width: 2rem
  padding-left: 0.2rem
  padding-right: 0.2rem

.data-item-monitoring-sheet
  table
    th.position
      width: 30px
    th.value
      min-width: 150px
      width: 180px
  .simple_format
    font-style: italic
    &:last-of-type
      margin-bottom: 0


// new/create
// ---------------------------------------------------------------------

#new_monitoring_sheet
  .card-header
    background: $gray-lightest
    border-top-right-radius: calc(#{$border-radius-lg} - 1px)
    border-top-left-radius: calc(#{$border-radius-lg} - 1px)
  #monitoring_template_header_infos
    @extend .bg-secondary
    @extend .text-white
    margin-top: $card-spacer-y * 1.5
    margin-left: - $card-spacer-x
    margin-right: - $card-spacer-x
    margin-bottom: - ($card-spacer-y + 0.03rem)
    padding: $card-spacer-y $card-spacer-x
  #monitoring_sheet-evaluate
    border: 1px solid $gray-lighter
    border-radius: $border-radius-lg
  #monitoring_sheet_entries
    @extend .box_shadow_item
    border-bottom: 1px solid $gray-lighter
    border-bottom-left-radius: $border-radius-lg
    border-bottom-right-radius: $border-radius-lg
    .monitoring_sheet
      overflow: hidden
      background-color: white
      border-radius: 0
      border: 1px solid $gray-lighter
      border-bottom-width: 0
      border-right-width: 0
      border-left-width: 0
      &.open
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1)
        position: relative
        z-index: 1000
        margin: -5px -10px
        border-width: 1px
        border-radius: $border-radius-lg
        .header
          padding-top: 5px
          padding-bottom: 5px
          padding: 5px calc($card-spacer-x + 9px)
        .body-inner
          padding-right: calc($card-spacer-x + 9px)!important
          padding-left: calc($card-spacer-x + 9px)!important
      &:last-child
        border-bottom-width: 0
      &.defective
        .header
          background-color: #f9dee0
      &.not_inspected
        .header
          text-decoration: line-through
          background-color: $gray-lighter
          a
            color: $gray!important
        .body-inner
          opacity: 0.5
      &:first-child
        border-top-left-radius: $border-radius-lg
        border-top-right-radius: $border-radius-lg
      &:last-child
        border-bottom-left-radius: $border-radius-lg
        border-bottom-right-radius: $border-radius-lg
        border-bottom-width: 1px
      .header
        margin: 0
        padding-left: $card-spacer-x
        padding-right: $card-spacer-x
        background-color: #ecf3ec
        a
          display: block
          padding-top: 17px
          padding-bottom: 17px
          position: relative
          padding-right: 20px
          &:after
            content: '' // Fontawesome caret-up
            position: absolute
            top: 50%
            right: 0
            font-family: FontAwesome
            font-size: 18px
            height: 16px
            margin-top: -10px
            opacity: 0.5
          &.collapsed
            &:after
              content: '' // Fontawesome caret-down
      .body
        .body-inner
          border-top: 1px solid $gray-lighter
          padding: 15px $card-spacer-x 10px $card-spacer-x
    .points_target_actual_slider_container
      margin-bottom: 35px
      padding: 0 20px
      +media-breakpoint-up(md)
        padding: 0 40px
    .form-group
      margin-bottom: 0
      &.points_target,
      &.points_actual
        float: left
        width: 50%
        margin-bottom: 0
        background: transparent
        label
          margin: 0
          &:after
            content: ": "
        input
          display: inline-block!important
          color: $body-color!important
          width: auto!important
          padding: 0 0 0 5px
          background: transparent
          border: none
          box-shadow: none
      &.points_actual
        text-align: right
        input
          margin-right: -15px
      &.percent
        clear: both
        height: 45px
        text-align: center
        &.percent-traffic-light
          .slider
            padding-right: 8px
            padding-left: 8px
          .slider-tick-label
            +hide-text
            width: auto!important
            margin-top: 5px
            &:after
              content: ' '
              display: inline-block
              height: 16px
              width: 16px
              border-radius: 50%
              background-color: $danger
            &:nth-child(1)
              &:after
                margin-left: -8px
            &:nth-child(2)
              &:after
                content: ''
                background-color: $warning
            &:last-child
              &:after
                content: ''
                margin-right: -8px
                background-color: $success
      &.toggle-disabled
        text-align: right
        margin-bottom: 5px
        .btn
          padding-left: 0
