// index
// ---------------------------------------------------------------------

body.notices-index
  .priority_indicator,
  .state_indicator
    margin-top: -0.2rem


// show
// ---------------------------------------------------------------------

.notice-message-content
  line-height: 1.4
  +media-breakpoint-up(md)
    font-size: 1.0625rem


// State & Prio indicators
// ---------------------------------------------------------------------

.state_indicator
  @extend .btn
  @extend .btn-sm
  @extend .btn-unclickable
  display: inline-flex
  align-items: center
  justify-content: center
  pointer-events: auto
  cursor: default
  font-size: 9px
  line-height: 8px
  height: 18px
  width:  18px
  margin-top: -3px
  padding: 0!important
  background: white
  &.state_indicator_open
    @extend .btn-outline-secondary
    color: $gray
  &.state_indicator_approve
    @extend .btn-outline-secondary
  &.state_indicator_close,
  &.state_indicator_accept
    @extend .btn-primary
  &.state_indicator_begin_work
    @extend .btn-info
    font-size: 15px
  &.state_indicator_deny
    @extend .btn-outline-secondary
    color: white
    font-size: 12px
    font-weight: bold
    background: $gray-light!important

.priority_indicator
  @extend .btn
  @extend .btn-sm
  @extend .btn-unclickable
  pointer-events: auto
  text-indent: -999em
  cursor: default
  height: 18px
  width:  18px
  margin-top: -3px
  &.priority_indicator_low
    @extend .btn-success
  &.priority_indicator_medium
    @extend .btn-warning
  &.priority_indicator_high
    @extend .btn-danger

.grades_result,
.lights_result
  @extend .btn
  @extend .btn-sm
  cursor: default
  font-size: 11px
  vertical-align: text-top
  margin-top: 3px
  padding: 0
.grades_result
  margin-top: 1px


// Messages
// ---------------------------------------------------------------------
.data-item-content,
.data-item-message
  .card-header
    border-bottom: none

.data-item-message
  border: 1px solid $gray-lighter
  border-bottom-width: 2px

.notice-message-autoclose-container
  display: flex
  justify-content: space-between
  .notice-message-autoclose-icon-container
    flex-basis: 5rem
    margin-right: 1rem
    .notice-message-autoclose-icon
      display: inline-flex
      align-items: center
      justify-content: center
      color: white
      height: 2rem
      width: 2rem
      margin-left: 3rem
      background: $brand-primary
      border-radius: $border-radius
  .notice-message-autoclose-meta
    margin-top: 0.3rem
    flex-grow: 1

.notice-message-connector
  height: 2.5rem
  width: 2px
  margin-left: 4rem
  background: $gray-lighter
