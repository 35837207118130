// Buttons
// ---------------------------------------------------------------------

.btn
  font-weight: bold
  letter-spacing: -0.04em
  white-space: nowrap
  &:active
    opacity: 0.7
    -webkit-tap-highlight-color: rgba(black ,0)
  .show > &
    box-shadow: none!important // no dropdown btn outline/box-shadow

// 3D btn-primary
.btn-primary-3d
  @extend .btn-primary
  background: linear-gradient(200deg, $brand-primary 50%, $brand-secondary)
  border: none

.btn-outline-dark
  color: $body-color
  &:active
    color: white
    background-color: $gray

.btn-blank
  @extend .btn-outline-secondary
  cursor: default
  background: none!important
  border: none
  &:focus,
  &:hover
    color: $body-color
    background: none
    box-shadow: none

.btn-clickable
  cursor: pointer

.btn-unclickable
  cursor: default!important
  pointer-events: none


// Cards
// ---------------------------------------------------------------------

.card
  @extend .box_shadow_default
  border-radius: $border-radius-lg
  &.card-active
    background-color: mix(lighten($success, 50), white, 75%)
    .list-group-item
      background: transparent
  .card-header,
  .card-body,
  .card-footer
    +media-breakpoint-up(lg)
      padding-left: $grid-gutter-width
      padding-right: $grid-gutter-width
  .card-header:first-child
    border-radius: calc(#{$border-radius-lg} - 1px) calc(#{$border-radius-lg} - 1px) 0 0
  .card-header-block
    a
      display: block

  .card-header-embossed
    border-color: transparent
    &.active
      border-bottom: 1px solid $gray-lightest

  &.card-title-alt
    .card-title
      font-weight: bold
      letter-spacing: -0.03em

  .grid-view &
    @extend .box_shadow_item
    .card-title
      font-size: 1.125rem
    .card-body,
    .card-footer
      +media-breakpoint-up(lg)
        padding-left: 1.25rem
        padding-right: 1.25rem
    &.card-image
      border-top-right-radius: 50px
      a
        &:focus,
        &:hover
          color: white
          text-decoration: none
          svg,
          img
            filter: brightness(80%)

    .image_thumbnail
      // +dog-ear($foreground-color: $gray-lightest, $background-color: $body-bg)
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0
      img,
      svg
        border-radius: $border-radius-lg $border-radius-lg 0 0!important
    > .card-header,
    > .card-body,
    > .card-footer
      border-top: none
      +media-breakpoint-down(xs)
        padding-left: 0.75rem
        padding-right: 0.75rem
    > .list-group-flush
      border-bottom: none
      > .list-group-item
        +media-breakpoint-down(xs)
          padding-left: 0.75rem
          padding-right: 0.75rem

  .card-outline
    box-shadow: none
    border-color: $gray-lighter
    .card-header
      background: transparent
      border-color: $gray-lighter

.js-tabcollapse-card-body
  .card-body
    padding: 0


// Images
// ---------------------------------------------------------------------

.image_thumbnail
  position: relative
  background: $gray-lightest
  border-radius: $border-radius
  &.zoom
    @extend .card
    cursor: zoom-in
    border-radius: $border-radius
    box-shadow: none!important
  img
    // @extend .rounded
    border-radius: calc(#{$border-radius} - 1px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
  .zoom
    @extend .btn
    @extend .btn-sm
    @extend .btn-dark
    @extend .btn-unclickable
    position: absolute
    bottom: $content-padding - 10
    right: $content-padding - 10
    background-color: rgba($gray-dark, 0.2)
    backdrop-filter: saturate(1.8) blur(10px)
    border: none
  &:active,
  &:focus,
  &:hover
    .zoom
      background-color: rgba(white, 0.1)!important


// Data items
// ---------------------------------------------------------------------

.data-item
  @extend .card
  @extend .box_shadow_item
  position: relative
  // border-top-right-radius: 50px
  // box-shadow: rgba(black, 0.01) 0px 27px 14px 0px, rgba(black, 0.01) 0px 20px 17px 0px performance on mobile when sidenav slides out is not great
  h5
    margin: 0
  p
    margin: 0
  // Vertical spacing between stacked columns
  .data-item-details,
  .data-item-content
    > .row
      margin-top: -15px
    div[class^="col-"]
      padding-top: 1.2rem
      +media-breakpoint-up(sm)
        margin-top: 0
  .data-item-details
    @extend .card-header
    @extend .sensible-word-break
    padding-top: 1.25rem
    padding-bottom: 1.25rem
    border: none
    background: $gray-lightest
    background-image: radial-gradient($gray-lightest, darken($gray-lightest, 4))
    box-shadow: inset 0 50px 70px rgba(white, 0.2), inset 0 0 50px rgba(black, 0.02)
    // +dog-ear($foreground-color: $gray-lightest, $background-color: $body-bg)
  .table-responsive
    border-radius: 0
    box-shadow: none

  .data-item-content
    @extend .card-body
  .data-item-footer
    @extend .card-footer
    border: none
    background: $gray-lightest
    background-image: radial-gradient(lighten($gray-lightest, 4), $gray-lightest)
  // Color Variants
  &.data-item-white
    .data-item-details
      background: white
      box-shadow: none
      // +dog-ear($foreground-color: white, $background-color: $body-bg)
  .tab-content
    box-shadow: none

.data-item-data-acquisition
  border-color: $gray-lighter
  border-bottom-width: 0.15rem
  .data-item-details
    h5
      font-weight: 800
      font-size: 1rem
      letter-spacing: -0.03em
      text-transform: uppercase


// Reports
// ---------------------------------------------------------------------

h1
  span.select_date
    color: $brand-primary

// Datepicker
.datepicker table
  tr
    td.active.active
      background: info
      &:active,
      &:focus,
      &:hover
        background: darken($info, 10)
    td.disabled
      color: $gray-light


// Tooltips
// ---------------------------------------------------------------------

.tooltip
  font-size: 14px



// Modals
// ---------------------------------------------------------------------

.modal-backdrop
  background-color: rgba($gray, 0.3)
  backdrop-filter: blur(6px) saturate(1.5)
  &.show
    opacity: 1

.modal
  .modal-header
    background: white
    border-top-right-radius: $border-radius-lg
    border-top-left-radius: $border-radius-lg
    border: none
    border-bottom: 1px solid $gray-lighter
    .close
      font-size: 2rem
      font-weight: bold
      line-height: 17px
      opacity: 1
      &:focus,
      &:hover
        color: $gray
  .modal-content
    color: $body-color
    background: $body-bg
    border: none
    overflow: hidden
    // border: 1px solid rgba(black, 0.5)
    // box-shadow: 0 5px 30px rgba(black, 0.2), 0 10px 50px rgba(black, 0.2)
    box-shadow: rgba(black, 0.2) 0px 27px 24px 0px, rgba(black, 0.2) 0px 40px 77px 0px

// Lightbox
@media all and (-ms-high-contrast:none)
  // I love you IE 11
  *::-ms-backdrop, .ekko-lightbox .modal-dialog
    flex: 100%!important
    margin-left: 0
    margin-right: 0
.ekko-lightbox
  .modal-dialog
    // Built in margin weirdly doesn't work for all images …
    margin: 0
    // … so we use padding instead
    padding: 0.5rem
    .modal-title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .modal-body
      padding: 0
      .ekko-lightbox-container
        margin-bottom: -1px!important
      .ekko-lightbox-item
        img
          margin-bottom: -1px
          border-bottom-right-radius: $border-radius-lg
          border-bottom-left-radius: $border-radius-lg
      .ekko-lightbox-nav-overlay
        a span
          text-shadow: 0 1px 0 rgba(black, 0.2), rgba(black, 0.3) 0px 3px 12px
.ekko-lightbox-loader
  > div
    display: none!important

#main_modal
  -webkit-overflow-scrolling: touch // smooth scrolling


// Misc
// ---------------------------------------------------------------------

.well
  color: white
  padding: $content-padding
  background: $brand-secondary
  // border-left: 5px solid $brand-secondary
  border-radius: $border-radius-lg
  // +dog-ear($size: 18px, $foreground-color: lighten($brand-secondary, 5), $background-color: $body-bg)
  p:last-child
    margin-bottom: 0
  a
    color: white
    text-decoration: underline

hr
  border-style: solid
  border-color: $gray-lighter
  &.sml
    text-align: left
    width: 20px
    margin: 10px 0
    border-width: 2px
    background-color: $body-color
    border-color: $body-color
  &.colored
    background-color: $brand-primary
    border-color: $brand-primary
  #page_sidenav &
    background-color: lighten($navmenu-default-bg, 15)
    border-color: lighten($navmenu-default-bg, 15)

// Scrollbar
// ---------

html,
#page_sidenav,
#tertiarynav,
.card,
.data-item,
.table-responsive
  scrollbar-width: thin!important
  scrollbar-color: $gray-lighter $body-bg
#page_sidenav
  scrollbar-color: $gray-lighter darken($gray-lightest, 1)
.card,
.data-item,
.table-responsive
  scrollbar-color: $gray-lightest white

// Webkit
#page_sidenav::-webkit-scrollbar,
#tertiarynav::-webkit-scrollbar,
.card::-webkit-scrollbar,
.data-item::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar
  height: 12px
  width: 11px

#page_sidenav::-webkit-scrollbar-thumb
  background-color: $gray-lighter
  border-radius: 6px
  border: 3px solid darken($gray-lightest, 1)
#tertiarynav::-webkit-scrollbar-thumb
  background-color: $gray-lighter
  border-radius: 6px
  border: 3px solid $body-bg
.card::-webkit-scrollbar-thumb,
.data-item::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb
  background-color: $gray-lightest
  border-radius: 6px
  border: 3px solid white


iframe
  margin: 10px 0

// Flash messages
.alert
  border: 1px solid rgba(black, 0.15)
  &#flash_message
    display: flex
    justify-content: space-between
    position: fixed
    top: $page_headernav_height * 0.5
    right: $grid-gutter-width
    left: $grid-gutter-width
    z-index: 1032 // pagenav has 1031, modal 1040
    max-width: 720px
    margin: 0 auto
    backdrop-filter: saturate(1.8) blur(8px)
    box-shadow: 0 3px 6px rgba($gray-darker, 0.1), 0 10px 20px rgba(0, 0, 0, 0.05)
    &.alert-success
      color: darken(map-get($theme-colors, primary), 20)
      background-color: rgba(lighten($brand-primary, 10), 0.65)
    &.alert-info
      background-color: rgba(lighten($brand-secondary, 20), 0.65)
    &.alert-danger
      background-color: rgba(lighten($brand-danger, 15), 0.65)
    &.alert-warning
      background-color: rgba(lighten($brand-warning, 15), 0.65)
  .content
    flex: 1 1 auto
  .close
    font-size: 22px
    color: rgba($body-color, 0.5)
    text-shadow: 0 -1px 0 rgba(white, 0.3)
    flex: 0 0 42px
    margin: -12px -8px -12px 12px
    padding-left: 10px
    opacity: 1!important
    border-left: 1px solid rgba(black, 0.1)
    &:focus,
    &:hover
      color: rgba($body-color, 0.6)
    span
      display: block
      margin-top: -2px

.turbolinks-progress-bar
   background-color: $brand-primary
   height: 3px

.animated-progress-container
  @extend .form-control
  position: relative
  overflow: hidden
  white-space: nowrap
  height: $custom-file-height
  opacity: 0.6
  background: #ccced5

.animated-progress
  position: absolute
  top: 0
  left: 0
  bottom: 0
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in
  transform: translate3d(0, 0, 0)
  background: $brand-primary linear-gradient(to right, $brand-primary 0%, lighten($brand-primary, 15) 50%, $brand-primary 100%) repeat
  background-size: 100px
  box-shadow: inset 0 0 1px rgba(black, 0.2)
  animation-name: moving-gradient
  animation-duration: 1s
  animation-iteration-count: infinite
  animation-timing-function: linear


// Result visualizations
// ---------------------------------------------------------------------

.grades_result
  $grade_size: 20px
  @extend .btn-outline-secondary
  font-weight: bold
  line-height: 1.7
  text-align: center
  height: $grade_size
  width:  $grade_size
  margin-top: -0.05rem

.lights_result
  $tl_size: 16px
  text-indent: -999em
  height: $tl_size
  width:  $tl_size
  margin-top: 0.05rem
  border-radius: 100%
  &.lights_result_green
    @extend .btn-success
  &.lights_result_yellow
    @extend .btn-warning
  &.lights_result_red
    @extend .btn-danger
  &.lights_result_large
    height: 32px
    width: 32px
    border-radius: 32px

.button_show_external_id
  @extend .btn
  @extend .btn-sm
  @extend .btn-outline-dark
  user-select: text // allow text selection
  padding-top: 0.3rem
  padding-bottom: 0.15rem
  span
    font-family: $font-family-monospace
    font-weight: bold
    letter-spacing: -0.035em


// Slider
// ---------------------------------------------------------------------

.slider.slider-horizontal
  cursor: pointer
  top: 0
  width: 100%
  margin-top: 5px!important // avoid jumping of slider
  .slider-track
    height: 2px
    margin-top: 0
  .slider-selection
    background: $brand-primary
  .slider-handle
    background: white
    border: 1px solid $gray-lighter
    box-shadow: 0 4px 5px rgba(black, 0.2)
    &.round
      top: -5px
      height: 30px
      width: 30px
      margin-left: -15px
  .slider-tick
    background: transparent
    box-shadow: none
  .slider-tick-label-container
    display: flex
    justify-content: space-between
    margin-left: 0!important
    .slider-tick-label
      width: auto!important
      margin-top: 5px


// Lists
// ---------------------------------------------------------------------

.list-group-transparent
  .list-group-item
    font-weight: bold
    letter-spacing: -0.05em
    background: transparent
    border-color: $gray-light
    &:focus,
    &:hover
      background: white


// Misc
// ---------------------------------------------------------------------

.user_role
  @extend .badge
  @extend .badge-pill
  @extend .badge-light
  @extend .text-muted
  background-color: rgba(white, 0.5)
  border: 1px solid rgba(black, 0.15)

#hint_development
  position: absolute
  z-index: 1032
  bottom: 100
  left: 50%
  width: 44px
  margin-left: -22px
  padding: 1px
  color: white
  font-size: 10px
  font-weight: bold
  letter-spacing: -0.03em
  text-align: center
  text-transform: uppercase
  background: $brand-primary
  border-radius: 0 0 5px 5px
  box-shadow: 0 3px 2px rgba($gray-darker, 0.1)

.decent-line-width
  max-width: 650px!important

.decent-line-width-large
  max-width: 800px!important

.sensible-word-break
  overflow-wrap: break-word
  word-wrap: break-word
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all
  /* Instead use this non-standard one: */
  word-break: break-word
  hyphens: auto

.box_shadow_default
  box-shadow: 0 6px 6px rgba($gray-darker, 0.02), 0 10px 20px rgba($gray-darker, 0.02)
  // box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.05), 0 18px 36px -18px rgba(0, 0, 0, 0.1), 0 -12px 36px -8px rgba(0, 0, 0, 0.025)

.box_shadow_item
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.05) 0px 8px 10px -6px

.async_loader
  @include loader(5px, 50px auto, $gray)
  &.async_loader_button
    @include loader(1.5px, 0, white)
    float: left
    margin-top: 0.1rem
    margin-right: 0.4rem
  &.async_loader_full
    position: absolute
    top: 50%
    left: 50%
    margin-top: -25px
    margin-left: -25px

.fa-tachometer
  vertical-align: -1%!important

.filename
  word-wrap: break-word

.nowrap
  white-space: nowrap

.dropdown-menu
  box-shadow: rgba(black, 0.1) 0px 3px 8px, rgba(black, 0.05) 0px 5px 20px

.fa-spin-fast
  animation-duration: 0.5s

// Collapsible items
span.text-expand
  display: none
.collapsed
  span.text-expand
    display: inline
  span.text-collapse
    display: none


// Charts
// ---------------------------------------------------------------------

div[id^="chart-"]
  @extend .box_shadow_default
  padding: 10px
  background: white
  border-radius: $border-radius
  .card-body &
    box-shadow: none
  canvas
    width: 100%


// Rating (stars)
// ---------------------------------------------------------------------

.rating
  white-space: nowrap
  input
    height: 0!important
    width: 0!important
  .rating_star
    display: inline-block
    background: none
    border: none
    font: inherit
    outline: inherit
    .rating_star_image
      height: 1rem
      width: 1rem
      margin-right: 0.75rem
      fill: $gray-lighter
      stroke: $gray-lighter
    &.full
      .rating_star_image
        fill: $brand-warning
        stroke: $brand-warning
.rating.form
  .rating_star
    cursor: pointer
    .rating_star_image
      height: 2.2rem
      width: 2.2rem
      margin-right: 1rem
      &:focus,
      &:hover
        fill: $brand-warning
        stroke: $brand-warning

// #letter_opener_button
//   position: fixed
//   z-index: 1031 // 1 higher than sidenav
//   font-size: 24px
//   bottom: 40px
//   right: 40px
//   height: 44px
//   width: 44px
//   line-height: 41px
//   color: white
//   text-align: center
//   background: linear-gradient(235deg, $brand-primary 40%, $brand-secondary)
//   border-radius: 5px
//   box-shadow: 0 3px rgba(black, 0.1)
