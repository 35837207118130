// Pagination
// ---------------------------------------------------------------------

.pagy-bootstrap.combo-nav-js
  text-align: center
  position: relative
  margin: 60px 0 40px
  .pagination
    @extend .btn-group
    @extend .list-unstyled
  .page-item
    @extend .btn
    @extend .btn-outline-secondary
    padding: 0
    background: transparent
  .prev a,
  .next a
    display: block
    color: $body-color
    font-family: FontAwesome
    font-size: 1.25rem
    text-decoration: none
    line-height: 100%
    padding: 0.75rem 1rem
  .disabled a
    color: $gray-lighter
  .pagy-bootstrap
    color: $body-color !important
    opacity: 1
    padding: 3px 10px
    background: transparent !important
    label
      margin-bottom: 0
    input
      @extend .form-control
      display: inline-block
      color: $body-color !important
      font-weight: bold
      height: 2.4rem
      background: transparent
      border: 1px solid $gray-lighter !important
      -webkit-appearance: none
      -moz-appearance: textfield
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
