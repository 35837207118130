@use 'sass:math'

@import '../stylesheets/variables'
@import '../stylesheets/bootstrap-custom'

$fa-font-path: '~font-awesome-4/fonts'
@import '~font-awesome-4/scss/font-awesome'
@import '~animate.css'
@import '../vendor/stylesheets/bootstrap-clockpicker'
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min'
@import '../vendor/stylesheets/jasny-bootstrap-variables'
@import '../vendor/stylesheets/jasny-bootstrap-navmenu'
@import '../vendor/stylesheets/jasny-bootstrap-offcanvas'
@import '~bootstrap-4-slider/src/sass/bootstrap-slider'
@import '~ekko-lightbox/dist/ekko-lightbox'
@import '~select2/dist/css/select2'
@import '../vendor/stylesheets/select2-bootstrap'
@import '../vendor/stylesheets/recurring-select'

@import '../stylesheets/mixins'
@import '../stylesheets/components'
@import '../stylesheets/direct_uploads'
@import '../stylesheets/forms'
@import '../stylesheets/layout'
@import '../stylesheets/navigation'
@import '../stylesheets/pagination'
@import '../stylesheets/select2-custom'
@import '../stylesheets/simple-calendar-custom'
@import '../stylesheets/tables'
@import '../stylesheets/typography'

@import '../stylesheets/controllers/inspections'
@import '../stylesheets/controllers/inspection_settings'
@import '../stylesheets/controllers/notices'
@import '../stylesheets/controllers/monitoring'
@import '../stylesheets/controllers/pages'
@import '../stylesheets/controllers/roles'
@import '../stylesheets/controllers/tickets'
@import '../stylesheets/controllers/time_trackings'
@import '../stylesheets/controllers/vehicles'

@import '../fonts/muli/muli'
@import '../fonts/firacode/firacode'
