.form-default,
.form-sm,
.form-embossed
  @extend .clearfix
  max-width: 1280px
  margin: 0 auto
  margin-top: 1.5rem
  +media-breakpoint-up(md)
    margin-top: 2rem
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="file"],
  textarea,
  select
    @extend .form-control
    line-height: 1.45
    height: auto
    &::placeholder
      color: $gray-lighter
.form-sm
  max-width: 1280px

.form-control.is-invalid
  background-image: none

.invalid-feedback
  font-size: 85%

// #filter_search
#filter_search
  .form-default
    @extend .card
    margin-bottom: 30px
    label
      white-space: nowrap

// Login, etc.
.form-embossed
  @extend .card
  @extend .box_shadow_default
  padding: $content-padding * 2
  @include media-breakpoint-down(xs)
    width: 100%
    margin-right: math.div($content-padding, 2)
    margin-left: math.div($content-padding, 2)
    padding: $content-padding
  @include media-breakpoint-up(sm)
    min-width: 380px
    max-width: 400px


// Buttons
// ---------------------------------------------------------------------

a,
input,
button
  &.normal-text
    color: $body-color
  &.crud_action
    @extend .btn
    &.crud_action_show,
    &.crud_action_edit,
    &.crud_action_update,
    &.crud_action_delete,
    &.crud_action_info
      @extend .btn-outline-dark
    &.crud_action_show,
    &.crud_action_edit,
    &.crud_action_delete,
    &.crud_action_info
      padding-top: 0.35rem
      padding-bottom: 0.35rem
    &.crud_action_new
      @extend .btn-primary
    &.crud_action_info
      @extend .btn-info
    &.crud_action_create
      @extend .btn-primary
      float: right


// Labels
// ---------------------------------------------------------------------

label
  font-weight: bold


// Misc
// ---------------------------------------------------------------------

.checkboxes-container,
.radio-buttons-container
  overflow: auto
  white-space: nowrap
  height: auto
  padding: $input-padding-y + 0.05rem $input-padding-x
  background: white
  border: 1px solid $input-border-color
  // Tell IE11 to behave
  @media all and (-ms-high-contrast:none)
    *::-ms-backdrop, input
      display: none
  +border-top-radius($border-radius)
  +border-bottom-radius($border-radius)
  &.constrained-height
    max-height: 200px
  &.disabled
    color: $gray-light
    background-color: $input-disabled-bg
  .radio
    margin: 0 ($grid-gutter-width * 0.25)
    input
      margin-top: 3px
  .form-group
    margin: 0
  .invalid-feedback
    display: block
  .custom-checkbox + .custom-checkbox
    margin-top: 1rem

.custom-checkbox .custom-control-label::before
  border-radius: $border-radius-sm

// Custom file upload field
.custom-file
  white-space: nowrap
  .custom-file-label
    font-weight: normal
    overflow: hidden
    &:after
      content: "\f115" !important
      font-family: FontAwesome !important
      font-size: 18px
      line-height: $custom-file-height
      text-align: center
      height: $custom-file-height
      width: 44px
      padding: 0

.input-group-text
  .fa
    line-height: 0
