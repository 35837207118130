body.inspection_settings-edit,
body.inspection_settings-update
  table.lights,
  table.grades
    td
      vertical-align: middle
    label
      text-align: right
      min-width: 60px
      padding-top: 8px
    .input-group
      margin-top: 15px
      max-width: 100px
  table.lights
    .lights_result
      @extend .lights_result_large
  table.grades
    .grades_result_large
      display: block
      font-size: 1.62em
      font-weight: bold
      text-align: center
      width: 32px
