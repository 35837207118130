// External pages
// ---------------------------------------------------------------------

@use "sass:math"

.unauthenticated
  &.pages-home,
  &.pages-privacy_policy,
  &.pages-safety_of_processing,
  &.pages-imprint,
  &.sessions-new,
  &.custom_confirmations,
  &.unlocks-new,
  &.notices-show_external,
  &.notices-edit_external
    // Fake a .container that wraps the #page_headernav content
    .header
      position: relative
      overflow: hidden
    #page_headernav,
    #footernav
      max-width: 1320px
      margin: 0 auto
      &:after
        content: " "
        position: fixed
        z-index: -1
        top: 0
        right: 0
        left: -999em
        display: block
        height: $page_headernav-height
        background: white!important
        border-bottom: 1px solid $gray-lighter
    #logo
      text-align: left
      margin-left: 0
      margin-right: 0
    #action_nav
      right: 15px
      left: auto
      ul li.sign_in
        i.fa
          display: none
        a
          @extend .btn
          @extend .btn-primary
          margin-top: 0.65em
          padding: 0.4em 1.1em 0.5em
          background: linear-gradient(235deg, $brand-primary 40%, $brand-secondary)
          border: none
          b,
          span
            display: inline-block


// Homepage (not logged in)
// ---------------------------------------------------------------------

.pages-home
  $homepage_padding: math.div($grid-gutter-width, 1.5)
  #page_content
    padding: 0
    #inner
      max-width: none
    .container
      padding-left: $homepage_padding
      padding-right: $homepage_padding
    .home_content
      padding: 0
      max-width: 1920px - $page_sidenav_width
      margin-right: auto
      margin-left: auto

  section.introduction
    padding-top: 1em
    padding-bottom: 9em
    background: white
    background: linear-gradient(177deg, white 0%, white 80%, transparent 80%, transparent 100%)
    +media-breakpoint-up(md)
      padding-bottom: 9em
    h1
      margin-top: 2em
      +media-breakpoint-up(md)
        font-size: 3em
    p.lead
      margin-top: 0.5em
      font-size: 1.44em
      +media-breakpoint-up(md)
        font-size: 2em
    .cityscape
      margin-top: 2em
      /* Avoid content jumping */
      position: relative
      height: 0
      padding-top: calc(573 / 1100 * 100%) // (height / width * 100%)
      +media-breakpoint-down(md)
        margin-right: -20px
        margin-left: -20px
        overflow: clip
        overflow-y: visible!important
      img
        position: absolute
        top: 0
        left: 0
        max-width: 100%
        height: auto
        +media-breakpoint-down(md)
          transform: scale(1.3)

  section.overview
    +media-breakpoint-down(xs)
      h1
        margin-top: -2em
    dl
      +media-breakpoint-down(md)
        padding-right: $grid-gutter-width * 0.75
        padding-left: $grid-gutter-width * 0.75
      dt,
      dd
        @extend .lead
        text-align: left
        margin-top: 0.35em
        +media-breakpoint-down(md)
          font-size: 1.12em
    i.fa-2x
      +media-breakpoint-down(md)
        font-size: 1.5em
        padding-top: 0.5rem

  section.modules
    margin-top: -12rem
    padding-top: 18rem
    padding-bottom: 18rem
    background: white
    background: linear-gradient(181deg, transparent 0%, transparent 10%, white 10%, white 90%, transparent 90%, transparent 100%)
    +media-breakpoint-up(md)
      margin-top: 2rem
      padding-top: 12rem
      padding-bottom: 12rem
    .row
      +media-breakpoint-down(md)
        padding-right: $grid-gutter-width * 0.75
        padding-left: $grid-gutter-width * 0.75
    p,
    ul
      @extend .lead
      +media-breakpoint-down(md)
        font-size: 1.12em
    ul
      margin-right: 0
      margin-left: 0
      padding-right: 0
      padding-left: 0
      list-style-type: none
      li
        padding-left: 1em
        &:before
          content: "∙ "
          color: $brand-secondary
          display: inline-block
          width: 1em
          margin-left: -1em

  section.references
    img
      filter: grayscale(0.97) contrast(1.25)

  // When references are active
  // section.recap
  //   margin-top: -12rem
  //   padding-top: 18rem
  //   padding-bottom: 18rem
  //   background: $gray_lighter
  //   background: linear-gradient(179deg, transparent 0%, transparent 10%, white 10%, $body-bg 100%)
  //   +media-breakpoint-up(md)
  //     margin-top: 2rem
  //     padding-top: 12rem
  //     padding-bottom: 0
  section.recap
    margin-top: -12rem
    +media-breakpoint-up(md)
      margin-top: 4rem
    p
      @extend .lead
      +media-breakpoint-down(md)
        font-size: 1.12em
        padding-right: $grid-gutter-width * 0.75
        padding-left: $grid-gutter-width * 0.75
    .devices
      margin-bottom: 20em
      /* Avoid content jumping */
      position: relative
      height: 0
      padding-top: calc(573 / 1100 * 100%) // (height / width * 100%)
      +media-breakpoint-down(md)
        margin-right: -20px
        margin-left: -20px
      img
        position: absolute
        top: 0
        left: 0
        max-width: 100%
        height: auto

// Dashboard
// ---------------------------------------------------------------------

.pages-dashboard
  .data-item
    margin-bottom: 2rem
    padding-bottom: 0.5rem
    .table-responsive
      background: transparent
  .data-item-details
    margin-bottom: 0
    padding: 0
    h4,
    h5
      font-size: 1.0625rem
      margin: 18px 0
      padding-left: $content-padding
  .table
    td,
    th
      +media-breakpoint-down(lg)
        padding-top: 0.6rem
        padding-bottom: 0.6rem
      &:first-child
        padding-left: $content-padding
      &:last-child
        padding-right: $content-padding

  .dashboard-calendar
    .data-item
      padding-bottom: 0
      overflow: hidden
    .simple-calendar
      .calendar-heading
        @extend .px-3
      .calendar-title
        @extend .pt-1
        color: $gray
        font-size: 1.0625rem
      .table
        margin-bottom: 0
        box-shadow: none
      .day
        padding-left: 8px
        padding-right: 8px
        +media-breakpoint-down(lg)
          padding-left: 3px
          padding-right: 3px
      .event
        +media-breakpoint-down(lg)
          padding-left: 1px
          padding-right: 1px

// New features
// ---------------------------------------------------------------------

.new_feature_banner
  @extend .well
  display: table
  width: 100%
  .new_feature_content
    display: table-row-group
    width: 100%
  .new,
  .info,
  .more
    display: table-cell
    vertical-align: middle
  .new_features_link
    display: block
    color: white
  .new
    span
      display: inline-block
      font-size: 0.89em
      font-weight: bold
      text-transform: uppercase
      letter-spacing: -0.07em
      transform: rotate(-90deg)
      // transform: rotate(-90deg)
  .info
    padding-right: 15px
    padding-left: 15px
    width: 99%
  .more
    text-align: right
    &:before
      content: " "
    a
      @extend .btn
      @extend .btn-sm
      @extend .btn-outline-light


// News/new features
// ---------------------------------------------------------------------

.new_feature
  @extend .decent-line-width
  margin-bottom: 4rem
  .meta
    margin-bottom: 1em
  h2
    margin-top: 20px
  p
    margin-bottom: 1rem!important
  // Paragraph, but not class .lead
  p:not(.lead),
  ul
    font-size: 1.0625rem

body.errors
  margin: 7% auto 0
  max-width: 640px
  min-height: 180px
  padding: 30px
  #logo
    display: inline-block
    height: 35px
    width: 120px
  #error_image
    @include media-breakpoint-up(md)
      margin-top: -80px


// Session new (login)
// ---------------------------------------------------------------------

.sessions
  &.sessions-new
    #logo
      text-align: center
      margin-right: auto!important
      margin-left: auto!important
    .input-group-text
      width: 40px
    #action_nav
      ul li.sign_in
        display: none

body.organizations-edit
  .image_thumbnail
    background-image: linear-gradient(45deg, $gray-lighter 25%, transparent 25%), linear-gradient(-45deg, $gray-lighter 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $gray-lighter 75%), linear-gradient(-45deg, transparent 75%, $gray-lighter 75%)
    background-size: 20px 20px
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px
