@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-regular-webfont.woff2') format('woff2'), url('../fonts/muli/muli-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-regularitalic-webfont.woff') format('woff');
    src: url('../fonts/muli/muli-regularitalic-webfont.woff2') format('woff2'), url('../fonts/muli/muli-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-bold-webfont.woff2') format('woff2'), url('../fonts/muli/muli-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-bolditalic-webfont.woff2') format('woff2'), url('../fonts/muli/muli-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-extrabold-webfont.woff2') format('woff2'), url('../fonts/muli/muli-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'CGE Sans';
    src: url('../fonts/muli/muli-extrabolditalic-webfont.woff2') format('woff2'), url('../fonts/muli/muli-extrabolditalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}
