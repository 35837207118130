.custom-file
  + .custom-file,
  + .direct-upload
    margin-top: 0.25rem

.direct-upload
  @extend .form-control
  position: relative
  overflow: hidden
  white-space: nowrap
  height: $custom-file-height

.direct-upload--pending
  opacity: 0.6

.direct-upload__progress
  position: absolute
  top: 0
  left: 0
  bottom: 0
  background: $brand-primary
  opacity: 0.2
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in
  transform: translate3d(0, 0, 0)

.direct-upload__filename
  position: absolute
  top: 0
  right: 0
  left: 0
  padding: $input-padding-y $input-padding-x
  padding-left: 50px

.direct-upload--complete .direct-upload__progress
  opacity: 0.4

.direct-upload--error
  border-color: red

input[type=file][data-direct-upload-url][disabled]
  display: none
  background-color: white

.finished-upload,
.direct-upload
  .custom-file-label
    font-weight: normal
    overflow: hidden
    padding-left: 50px
    &:after
      content: "\f014"!important
  .upload-preview
    position: absolute
    top: 0
    left: 0
    overflow: hidden
    text-align: center
    font-size: 0.7rem
    line-height: 2.5rem
    height: 40px
    width: 40px
    max-width: 40px
    white-space: normal
    background: $gray-lightest
    img
      max-width: 40px
      height: auto
      margin-top: -2px // unset line-height
